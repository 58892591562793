<template>  
<navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenu></sideMenu>
              </div>
              <div class="col-lg-9">

                <div>
                  <h4>Closing Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else class="row">
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                      <router-link
                                    :to="{
                                      name: 'AuctionDetails',
                                      params: {
                                        url_key: VehicleDetails.aucName,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  />
                                  </router-link>

                                  <!-- <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div> -->
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                     <router-link
                                    :to="{
                                      name: 'AuctionDetails',
                                      params: {
                                        url_key: VehicleDetails.aucName,
                                      },
                                    }"
                                  >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>
                                 
                                    <div
                                      class="row"
                                      v-if="VehicleDetails.reAucStatus == true"
                                    >
                                      <div class="col-12">
                                       
                                        <div class="text-center">
                                          <div
                                            v-if="
                                              VehicleDetails.aucRunningStatus ==
                                              0
                                            "
                                          >
                                          <p class="tile-small-txt">
                                              CURRENT BID PRICE
                                            </p>
                                            <h4
                                              style="
                                                font-size: 18px;
                                                font-weight: 700;
                                              "
                                            >
                                              ${{
                                                VehicleDetails.LatestBidAmount
                                              }}
                                            </h4>
                                            <button
                                              class="tile-bid-now"
                                              @click="showModal(VehicleDetails)"
                                            >
                                              BID NOW
                                            </button>
                                          </div>
                                          <div
                                            v-else-if="
                                              VehicleDetails.aucRunningStatus ==
                                              1
                                            "
                                          >
                                            <button
                                              class="tile-bid-now"
                                              disabled
                                            >
                                              Auction Ended
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn"
                  v-if="openModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close"
                    @click="(openModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>Auction Details</h4>
                  <p>
                    Brand :<span style="padding-left: 20%"
                      >{{ auctionEdit.brandName }} {{ auctionEdit.modelName }}
                      {{ auctionEdit.manYear }}</span
                    >
                  </p>
                  <p>
                    VIN No :
                    <span style="padding-left: 20%">{{
                      auctionEdit.vin
                    }}</span>
                  </p>
                  <p>
                    Highest bid amount :<span style="padding-left: 20%">{{
                      auctionEdit.LatestBidAmount
                    }}</span>
                  </p>
                  <p v-if="auctionEdit.custNegAmount">
                    Customer counter amount :<span style="padding-left: 20%">{{
                      auctionEdit.custNegAmount
                    }}</span>
                  </p>
                  <p v-if="auctionEdit.sellerNegAmount">
                    Last counter amount :<span style="padding-left: 20%">{{
                      auctionEdit.sellerNegAmount
                    }}</span>
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <p>No of Bidders :</p>
                    </div>
                    <div class="col-6">
                      <input
                        disabled
                        class="form-control"
                        type="text"
                        v-model="noOfBidders"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <p>Auction End Date :</p>
                    </div>
                    <div class="col-6">
                      <input
                        class="form-control"
                        type="time"
                        v-model="aucEndDate"
                      />
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="(openModel = false), (message = '')"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div class="col-4">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="acceptOffer(auctionEdit)"
                        >
                          Start
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
                <!-- Aucction Status Alert Model -->
                <div
                 
                  class="overlay-bid-btn warningStyle"
                  v-if="openShowModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openShowModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>

                   <p>
                    Are you sure you want to add
                    <span
                      >{{ auctionEdit.brandName }} {{ auctionEdit.modelName }}
                      {{ auctionEdit.manYear }}</span
                    >
                    to {{ modelnameAuc }}?
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openShowModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="changeAucStatus(auctionEdit, modelnameAuc)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="auctionStatusMessage == 'Success'"
                  >
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="auctionStatusMessage">
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                </div>
                <div
                 
                  class="overlay-bid-btn warningStyle"
                  v-if="openNegotiationModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openNegotiationModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4 style="color: white">Negotiation Alert</h4>

                   <p>
                    Are you sure to
                    {{ "  " + modelnameAuc + " " + negotiationAmount + "  " }}
                    for
                    <span>{{
                      auctionEdit.brandName + "" + auctionEdit.modelName
                    }}</span>
                    ?
                  </p>

                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openNegotiationModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="
                            openNegotiationModel = addNegotiation(
                              auctionEdit,
                              negotiationAmount
                            )
                          "
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
             <!-- modal -->
          <div class="overlay-bid-btn" v-if="bidModel">
            <button class="close-bid-btn bid-popup-close" @click="closeModal()">
              x
            </button>
            <h4>Bid Information</h4>           
            <p>Latest Bid Amount : {{ $n(amount, "currency", language) }}</p>
            <div class="auct-bid-pop-left-wrap">
              <div class="auct-bid-pop-left">
                <button class="btn btn-secondary" :class="{ 'buttonDisabled': isSubmitButtonDisabled }"
                  :disabled="isSubmitButtonDisabled" @click="decreaseBidAmount()"><span>-</span></button>
                <div class="auct-bid-pop-left-inner">
                  <input type="number" v-model="bidAmount" class="form-control" style="height: 37px" />
                </div>
                <button class="btn btn-secondary" @click="increaseBidAmount()"><span>+</span></button>
              </div>
              <button class="bid-info-sub" :class="{ 'buttonDisabled': isSubmitButtonDisabled }" @click="SubmitBid()"
                :disabled="isSubmitButtonDisabled">
                Submit
              </button>
            </div>
          </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenu from "@/components/sideMenu.vue";
import userService from "@/services/user.service";
import Config from "@/config";
import navbar from "@/components/navbar.vue";
// import CountDown from "@/components/modules/CountDown.vue";
export default {
  components: {
    sideMenu,
    PageNation,
    NotFound,
    navbar,
    // CountDown
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      openShowModel: false,
      auctionEdit: "",
      message: "",
      modelnameAuc: "",
      negotiationAmount: "",
      openNegotiationModel: false,
      customerNegAmount: null,
      auctionStatusMessage: "",
      modeNeg: "",
      noOfBidders: 3,
      aucEndDate: "",
      bidModel: false,
      bidAmount: "",
      selectedAuction: [],
      amount: "",
      language: Config.BASE_LANG,
      minIceament:""
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }, isSubmitButtonDisabled() {
      return this.bidAmount <= this.amount;
    },


  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.customerId != "undefined") {
        this.getCustomerClosingAuctions(this.userinfo.customerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.customerNegAmount = 200000;
  },
  methods: {
     // minIceament

     decreaseBidAmount() {

if (this.bidAmount >= this.amount) {
  this.bidAmount -= this.minIceament;
}

},
increaseBidAmount() {
this.bidAmount += this.minIceament;
},
    showModal(aucDetails) {
      this.bidModel = !this.bidModel;
      //console.log(aucDetails);
      this.amount = aucDetails.LatestBidAmount;
      this.minIceament = aucDetails.aucMinBid;
      this.bidAmount = aucDetails.latestBidAmount + this.minIceament;
      this.selectedAuction = aucDetails;
    },
    closeModal() {
      // this.isBitEntryModelActive = false;
      var self = this;
      setTimeout(function () {
        self.hideModal();
      }, 1000);
    },
    hideModal() {
      this.bidModel = false;
      this.message = "";
    },
    isMultipleOfHundred(number) {
  return number % this.minIceament === 0;
},
    SubmitBid() {
 this.message ="";

      if (
        this.userinfo.customerId &&
        this.bidAmount !== "" &&
        this.bidAmount !== null
      ) {
        let cusid = this.userinfo.customerId;
        let bidamount = parseInt(this.bidAmount);
        this.selectedAuction.latestBidAmount =
          this.selectedAuction.LatestBidAmount;
          if(!this.isMultipleOfHundred(this.bidAmount)){
            this.$toast.warning("Please enter a multiple of 100", {
              position: "top",
              duration: 3000,
              queue: true
            });
          }else {
        userService
          .addbid(this.selectedAuction, cusid, bidamount)
          .then((response) => {
            this.message = response.data.Message;
            this.message="";
            this.$toast.success(" Suceesfully added bid", {
              position: "top",
              duration: 3000,
              queue: true
            });
            setTimeout(function () {
             location.reload();
            }, 3000);
            this.closeModal();
            // let urlkey = this.$route.params.url_key;

            // this.getAuctionDetails(urlkey);
          })
          .catch((error) => {
            this.message =
              (error.response && error.response.data.Message) ||
              error.message ||
              error.toString();
              this.message="";            
              this.$toast.error(error.response.data.Message, {
          position: "top",
          duration: 3000,
          queue: true
        });
          }); }
      } else {
      
        

        this.$toast.error("Please enter a valid bid amount", {
          position: "top",
          duration: 3000,
          queue: true
        });
      }
    },
    rejectOffer(e) {
      this.openNegotiationModel = !this.openNegotiationModel;
      this.modelnameAuc = "reject  the customer offer amount ";
      this.auctionEdit = e;
      this.modeNeg = "Canceled";
      if (e.custNegAmount != null) {
        this.negotiationAmount = e.custNegAmount;
      } else {
        this.negotiationAmount = e.LatestBidAmount;
      }
      // this.updateAuctionStatus(e, "Canceled");
    },
    negotiateOffer(negotiationDetail) {
      let offerAmount = parseInt(this.negotiationAmount);

      if (offerAmount) {
        this.message = "";
        this.modeNeg = "Negotiate";
        this.auctionEdit = negotiationDetail;
        this.openNegotiationModel = !this.openNegotiationModel;
        this.modelnameAuc = " offer the amount to: ";
      } else {
        this.message = "Plaese enter a negotiation amount";
      }
    },
    acceptOffer(e) {
      // alert("Accepted offer amount :" +  e.LatestBidAmount)
      //  var self = this;
      //  setTimeout(function () {
      //   self.closeModel();
      // }, 2000);

      if (this.aucEndDate != "") {
        this.message = "Auction started";
        console.log(e, this.aucEndDate);
      } else {
        this.message = "please select a date";
      }
      // this.modeNeg = "Sale Confirmed";
      // this.openNegotiationModel = !this.openNegotiationModel;
      // this.modelnameAuc = "accept customer offer amount";
      // this.auctionEdit = e;

      // if (e.custNegAmount != null) {
      //   this.negotiationAmount = e.custNegAmount;
      // } else {
      //   this.negotiationAmount = e.LatestBidAmount;
      // }
      // this.updateAuctionStatus(e, this.modeNeg);
    },
    changeAucStatus(aucInfo, aucStatus) {
      this.updateAuctionStatus(aucInfo, aucStatus);
    },

    closeModel() {
      this.message = "";
      this.openModel = false;
    },
    closeopenShowModel() {
      this.auctionStatusMessage = "";
      this.openShowModel = false;
    },
    reAuction(e) {
      this.auctionEdit = e;
      this.openModel = !this.openModel;
      this.openShowModel = false;
    },
    ShowAlert(e, r) {
      this.modelnameAuc = r;
      this.auctionEdit = e;
      this.openShowModel = !this.openShowModel;
      this.openNegotiationModel = false;
      this.openModel = false;
    },
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    updateAuctionStatus(auctionDetails, aucStatus) {
      //  console.log(auctionDetails,aucStatus);
      userService
        .updAuctionStatus(auctionDetails, aucStatus)
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          if (this.auctionStatusMessage == "Success") {
            var self = this;
            setTimeout(function () {
              self.closeopenShowModel();
            }, 2000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addNegotiation(negotiationDetail, offerAmount) {
      if (this.modeNeg == "Sale Confirmed") {
        this.updateAuctionStatus(negotiationDetail, this.modeNeg);
      } else if (this.modeNeg == "Canceled") {
        this.updateAuctionStatus(negotiationDetail, this.modeNeg);
      } else if (this.modeNeg == "Negotiate") {
        userService
          .insNegotiationDetails(negotiationDetail, offerAmount)
          .then((response) => {
            this.auctionStatusMessage = response.data.Message;
            if (this.auctionStatusMessage == "Success") {
              var self = this;
              setTimeout(function () {
                self.closeopenShowModel();
              }, 2000);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getCustomerClosingAuctions(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getCustomerClosingAuctions(id, "Closing")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
           // console.log(this.AuctionsDetails);
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
            this.vechApproval = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>

.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.buttonDisabled {
  opacity: 0.2;
}
</style>
